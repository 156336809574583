import { fetchRequest } from './rest'

export function login(email: string, password: string) {
  return fetchRequest('/auth/affiliate/login', 'post', { email, password })
}

export function signup(email: string, referralCode: string) {
  return fetchRequest('/auth/affiliate/register', 'post', {
    email,
    referralCode
  })
}

export function forgotPassword(email: string) {
  return fetchRequest('/auth/reset-password', 'get', { email })
}

export function resetPasswordOtp(otp: string) {
  return fetchRequest(`/auth/reset/${otp}`, 'get')
}

export function setNewPassword(password: string) {
  return fetchRequest('/auth/reset-password', 'post', { password })
}

export function getDetails(params?: any) {
  return fetchRequest('/auth/affiliate/details', 'get', params)
}
