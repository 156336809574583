import { Theme, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  rise: {
    palette: {
      gradient: [
        'linear-gradient(270.45deg, #22D8E2 0.37%, #0898A0 81.51%)',
        'linear-gradient(278.97deg, #22D8E2 0.37%, #0898A0 81.51%)'
      ],
      grey: ['#f7f7fa']
    }
  },
  palette: {
    primary: { light: '#58E8F0', main: '#0898A0', dark: '#0090B0' },
    secondary: { light: '#58E8F0', main: '#0898A0', dark: '#0090B0' },
    text: { primary: 'rgb(23, 34, 60)' }
  },
  typography: {
    fontFamily: [
      // '"IBM Plex Sans"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: { fontWeight: 600, fontSize: '2rem' },
    h2: { fontWeight: 500, fontSize: '1.5rem' },
    h3: { fontWeight: 400, fontSize: '1.3rem' },
    h5: { fontWeight: 300, fontSize: '1rem' },
    subtitle1: {
      fontSize: '12px'
    }
  }
} as Theme)

export default theme
