import { Theme, withStyles } from '@material-ui/core/styles'
import composeGenericStyles from './composeGenerics'
const bgImage = require('shared/images/rise-affiliat-bg-image.png')

const GlobalCss = withStyles((theme: Theme) => ({
  '@global': {
    ...composeGenericStyles(theme),
    '.MuiFormGroup-root': {
      width: '100%'
    },
    '.Background-grey': {
      '&.root': {
        flexGrow: 1,
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'repeat'
      },
      '&.container': {
        width: '100vw',
        minHeight: '90vh',
        display: 'flex',
        paddingTop: '2rem',
        justifyContent: 'center'
      }
    },
    '.Flex': {
      display: 'flex',
      '&.center': {
        justifyContent: 'center',
        alignItems: 'center'
      },
      '&.justify-between': {
        justifyContent: 'space-between'
      },
      '&.justify-around': {
        justifyContent: 'space-around'
      },
      '&.column': {
        flexDirection: 'column'
      }
    },
    '.Capitalize': {
      textTransform: 'capitalize'
    },
    '.Pointer': {
      cursor: 'pointer'
    },
    '.Ellipisis': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%'
    },
    '.Text-grey': {
      color: '#8B909D'
    }
  }
}))(() => null)

export default GlobalCss
