import AsyncComponentLoader from 'components/Loaders/Component'
import Loadable from 'react-loadable'

export default function asyncComponent(importComponent: any) {
  const AsyncComponent = Loadable({
    delay: 300, // 0.3 seconds
    loader: importComponent,
    loading: AsyncComponentLoader,
    timeout: 10000 // 10 seconds
  })

  return AsyncComponent
}
