import { LocationDescriptor } from 'history'
import React from 'react'
import { Redirect } from 'react-router-dom'

interface IRedirectIf {
  children: JSX.Element
  condition: boolean | ((to?: LocationDescriptor) => LocationDescriptor)
  to?: LocationDescriptor
}

const RedirectIf: React.SFC<IRedirectIf> = ({ children, condition, to }) => {
  if (typeof condition === 'function') {
    const target = condition(to)
    return target ? <Redirect to={target} /> : children
  }
  if (!to) {
    // tslint:disable-next-line:no-console
    console.warn(
      "Missing property: RedirectIf component requires the 'to`' property"
    )
    return null
  }
  return condition ? <Redirect to={to} /> : children
}

export default RedirectIf
