import { routerReducer as router } from 'react-router-redux'
import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import application from 'redux/application/reducer'
import { IApplicationState } from 'redux/application/interfaces'
import { IUserState } from 'redux/user/interfaces'
import user from 'redux/user/reducer'

export interface IRootReducer {
  application: IApplicationState
  form: {}
  router: { location: any }
  user: IUserState
}

export default combineReducers<IRootReducer>({
  application,
  form,
  router,
  user
})
