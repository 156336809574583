import React from 'react'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import { Route } from 'react-router-dom'
import Routes from 'routes'
import 'typeface-roboto'

const Root = ({ history, store }: any) => (
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={Routes} />
    </Router>
  </Provider>
)

export default hot(module)(Root)
