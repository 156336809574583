const asFormDetails = (
  name: string,
  fields: string[],
  required?: string[]
) => ({ name, fields, required })

const passwordResetFields = ['email']
const verifyOtpFields = ['otp']
const loginFields = ['email', 'password']
const signupFields = ['email', 'referralCode']
const newPasswordFields = ['password', 'confirmPassword']

export const loginForm = asFormDetails('login', loginFields, loginFields)
export const signupForm = asFormDetails('signup', signupFields, signupFields)
export const verifyOtpForm = asFormDetails('verifyOtp', verifyOtpFields, verifyOtpFields)
export const passwordResetForm = asFormDetails('passwordReset', passwordResetFields, passwordResetFields)
export const newPasswordForm = asFormDetails('newPassword', newPasswordFields, newPasswordFields)
