import { createBrowserHistory } from 'history'
import { matchPath } from 'react-router-dom'

const history = createBrowserHistory({
  basename: '/'
})

const allParams = '/:path/:resType/:param?/:subParam?'

interface IAiParams {
  path: string
  resType: string
  subParam?: string
  param?: string
}

const matchAiParams = () => {
  const match = matchPath<IAiParams>(history.location.pathname, {
    path: allParams
  })
  if (!match) {
    return {} as IAiParams
  }

  return { ...match.params }
}

export default history
export { matchAiParams }
