enum Types {
  LOGIN_REQUEST = 'user/LOGIN_REQUEST',
  LOGIN_SUCCESS = 'user/LOGIN_SUCCESS',
  LOGIN_ERROR = 'user/LOGIN_ERROR',

  SIGNUP_REQUEST = 'user/SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'user/SIGNUP_SUCCESS',
  SIGNUP_ERROR = 'user/SIGNUP_ERROR',

  LOGOUT_REQUEST = 'user/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS',
  LOGOUT_ERROR = 'user/LOGOUT_ERROR',

  PASSWORD_RESET_REQUEST = 'user/PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS = 'user/PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERROR = 'user/PASSWORD_RESET_ERROR',

  VERIFY_OTP_REQUEST = 'user/VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS = 'user/VERIFY_OTP_SUCCESS',
  VERIFY_OTP_ERROR = 'user/VERIFY_OTP_ERROR',

  SET_NEW_PASSWORD_REQUEST = 'user/SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCESS = 'user/SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_ERROR = 'user/SET_NEW_PASSWORD_ERROR',

  GET_AUTH_USER_ERROR = 'user/GET_AUTH_USER_ERROR',

  SET_AUTH_TOKEN = 'user/SET_AUTH_TOKEN',
  SET_USER_PROFILE = 'user/SET_USER_PROFILE',

  GET_COMMISSION_REQUEST = 'user/GET_COMMISSION_REQUEST',
  GET_COMMISSION_SUCCESS = 'user/GET_COMMISSION_SUCCESS',
  GET_COMMISSION_ERROR = 'user/GET_COMMISSION_ERROR',

  ERROR = 'user/ERROR',
  CLEAR_ERROR = 'user/ERROR_CLEAR'
}

export default Types
