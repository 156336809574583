import NotificationBar from 'components/NotificationBar'
import { connect } from 'react-redux'
import {
  getNotification,
  isOnline,
} from 'redux/application/selectors'
import { IRootReducer } from 'redux/rootReducer'
import { clearNotification } from 'redux/application/actions'
import { clearError } from 'redux/user/actions'
import { getError } from 'redux/user/selectors'

const mapStateToProps = (state: IRootReducer) => ({
  error: getError(state),
  isOffline: !isOnline(state),
  notification: getNotification(state),
})

const mapDispatchToProps = {
  clearError,
  clearNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBar)
