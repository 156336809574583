import {
  forgotPassword,
  login,
  getDetails,
  resetPasswordOtp,
  setNewPassword,
  signup
} from 'api'
import {
  removeSessionData,
  saveSessionToken,
  saveSessionUserData
} from 'api/session'
import { LOCATION_CHANGE, LocationChangeAction, push } from 'react-router-redux'
import { setNotification } from 'redux/application/actions'
import {
  loginForm,
  newPasswordForm,
  passwordResetForm,
  signupForm,
  verifyOtpForm
} from 'redux/forms'
import { destroy, startSubmit, stopSubmit } from 'redux-form'
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest
} from 'redux-saga/effects'
import { setAuthToken } from './actions'
import * as I from './interfaces'
import { getUser } from './selectors'
import t from './types'

function* getDetailsSaga() {
  try {
    const {
      data: { data: user }
    } = yield call(getDetails)

    saveSessionUserData(user)
    yield put({ type: t.SET_USER_PROFILE, user })
    yield put({ type: t.LOGIN_SUCCESS })
    return true
  } catch (error) {
    yield put({ type: t.GET_AUTH_USER_ERROR, error })
    return error
  }
}

function* getCommisionSaga({ paginate }: I.IGetCommisionRequest) {
  try {
    const {
      meta: { limit, offset }
    }: IUser = yield select(getUser)

    const q = paginate ? { $offset: offset + limit } : undefined

    const {
      data: { data: user }
    } = yield call(getDetails, q)

    yield put({
      type: t.GET_COMMISSION_SUCCESS,
      paginate,
      user
    })
  } catch (error) {
    yield put({ type: t.GET_COMMISSION_ERROR, error })
  }
}

function* loginSaga({ email, password }: I.ILoginRequest) {
  try {
    yield put(startSubmit(loginForm.name))
    const {
      data: { token, expires }
    } = yield call(login, email, password)
    yield put(setAuthToken(token))

    saveSessionToken({ token, expires })
    yield call(getDetailsSaga)
    yield put(stopSubmit(loginForm.name))
  } catch (error) {
    yield put({ type: t.LOGIN_ERROR, error })
    yield put(stopSubmit(loginForm.name))
  }
}

function* signupSaga({ email, referralCode }: I.ISignupRequest) {
  try {
    yield put(startSubmit(signupForm.name))
    yield call(signup, email, referralCode)
    yield put({ type: t.SIGNUP_SUCCESS })
    yield put(stopSubmit(signupForm.name))
    yield put(setNotification("Request sent. We'll contact you soon"))
  } catch (error) {
    yield put({ type: t.SIGNUP_ERROR, error })
    yield put(stopSubmit(signupForm.name))
  }
}

function* passwordResetSaga({ email }: I.ILoginRequest) {
  try {
    yield put(startSubmit(passwordResetForm.name))
    yield call(forgotPassword, email)
    yield put(stopSubmit(passwordResetForm.name))
    yield put({ type: t.PASSWORD_RESET_SUCCESS })
    yield put(push('/reset_password/otp'))
  } catch (error) {
    yield put(stopSubmit(passwordResetForm.name))
    yield put({
      type: t.PASSWORD_RESET_ERROR,
      error
    })
  }
}

function* verifyOtpdSaga({ otp }: I.IVerifyOtpRequest) {
  try {
    yield put(startSubmit(verifyOtpForm.name))

    const {
      data: { token, expires }
    } = yield call(resetPasswordOtp, otp)
    yield put(setAuthToken(token))

    saveSessionToken({ token, expires })
    const profileSuccessOrError = yield call(getDetailsSaga)

    if (typeof profileSuccessOrError === 'boolean') {
      yield put({ type: t.VERIFY_OTP_SUCCESS })
      yield put(stopSubmit(verifyOtpForm.name))
      yield put(push('/reset_password/new'))
    } else {
      throw new Error(profileSuccessOrError)
    }
  } catch (error) {
    yield put(stopSubmit(verifyOtpForm.name))
    yield put({
      type: t.VERIFY_OTP_ERROR,
      error
    })
  }
}

function* setNewPasswordSaga({ password }: I.ISetNewPasswordRequest) {
  try {
    yield put(startSubmit(newPasswordForm.name))
    yield call(setNewPassword, password)
    yield put({ type: t.SET_NEW_PASSWORD_SUCCESS })
    yield put(stopSubmit(newPasswordForm.name))
    yield put(setNotification('Password successfully reset'))
    yield put(push('/'))
  } catch (error) {
    yield put(stopSubmit(newPasswordForm.name))
    yield put({
      type: t.SET_NEW_PASSWORD_ERROR,
      error
    })
  }
}

function* logoutSaga() {
  removeSessionData()
  yield put({ type: t.LOGOUT_SUCCESS })
  yield put(push('/'))
}

function* routeChangeSaga() {
  while (true) {
    yield take(
      (action: LocationChangeAction) =>
        action.type === LOCATION_CHANGE &&
        action.payload.pathname.startsWith('/dashboard')
    )
    yield put(destroy(loginForm.name))
    yield put(destroy(signupForm.name))
  }
}

function* watchLoginSaga() {
  yield takeLatest(t.LOGIN_REQUEST, loginSaga)
}

function* watchSignupSaga() {
  yield takeLatest(t.SIGNUP_REQUEST, signupSaga)
}

function* watchLogoutSaga() {
  yield takeLatest(t.LOGOUT_REQUEST, logoutSaga)
}

function* watchPasswordResetSaga() {
  yield takeLatest(t.PASSWORD_RESET_REQUEST, passwordResetSaga)
}

function* watchVerifyOtpdSaga() {
  yield takeLatest(t.VERIFY_OTP_REQUEST, verifyOtpdSaga)
}

function* watchSetNewPasswordSaga() {
  yield takeLatest(t.SET_NEW_PASSWORD_REQUEST, setNewPasswordSaga)
}

function* watchGetCommisionSaga() {
  yield takeLatest(t.GET_COMMISSION_REQUEST, getCommisionSaga)
}

export default function*() {
  yield all([
    fork(watchLoginSaga),
    fork(watchSignupSaga),
    fork(watchLogoutSaga),
    fork(watchPasswordResetSaga),
    fork(watchVerifyOtpdSaga),
    fork(watchSetNewPasswordSaga),
    fork(watchGetCommisionSaga)
  ])

  // this should be at the end to prevent blocking other sagas
  yield fork(routeChangeSaga)
}
