import NotificationBar from 'containers/notificationBar'
import React, { Fragment, PureComponent } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import asyncComponent from 'resources/utils/asyncComponentLoader'
import { renderProtectedRoute } from 'routes/helpers'
import { Box, CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'
import GlobalStyle from './style'

const AuthRoute = asyncComponent(() => import('./Auth'))
const DashboardRoute = asyncComponent(() => import('./Dashboard'))

class Routes extends PureComponent<RouteComponentProps> {

  render() {
    return (
      <Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <main>
            <NotificationBar />
            <Box fontFamily="IBM Plex Sans" color="text.primary">
              <Switch>
                {renderProtectedRoute(DashboardRoute, '/dashboard')}
                <Route component={AuthRoute} />
              </Switch>
            </Box>
          </main>
        </ThemeProvider>
      </Fragment>
    )
  }
}

export default Routes
