import React from 'react'

interface IAsyncComponentLoaderProps {
  error?: any
  isLoading: boolean
  pastDelay: boolean
  timedOut: boolean
}

const style: any = {
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh'
}

const AsyncComponentLoader = ({
  error,
  pastDelay,
  timedOut
}: IAsyncComponentLoaderProps) => {
  const ErrorEl = (
    <div style={style}>
      <h4>
        An error has occured... Please check your internet connection and reload
        the page
      </h4>
    </div>
  )

  const LoaderEl = (
    <div style={style}>
      <h4>Loading...</h4>
    </div>
  )

  const TimedOutEl = (
    <div style={style}>
      <h4>
        Taking a rather long time... Please check your internet connection
      </h4>
    </div>
  )

  if (error) {
    return ErrorEl
  } else if (timedOut) {
    return TimedOutEl
  } else if (pastDelay) {
    return LoaderEl
  } else {
    return null
  }
}

export default AsyncComponentLoader
