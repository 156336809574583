import { getUserData, getAuthToken } from 'api/session'
import * as I from './interfaces'
import t from './types'

interface IActionHandler {
  readonly [r: string]: (
    state: I.IUserState,
    action: I.UserInterfaces
  ) => I.IUserState
}

const initialState = {
  error: null,
  isAuthorized: !!getAuthToken(),
  isLoading: false,
  isLoggingOut: false,
  token: getAuthToken(),
  user: getUserData()
}

const ACTION_HANDLERS: IActionHandler = {
  [t.LOGIN_REQUEST]: (state: I.IUserState) => ({
    ...state,
    error: null
  }),
  [t.LOGIN_ERROR]: (state: I.IUserState) => ({
    ...state
  }),
  [t.LOGIN_SUCCESS]: (state: I.IUserState) => {
    return {
      ...state,
      isAuthorized: true
    }
  },
  [t.SET_USER_PROFILE]: (state: I.IUserState, { user }: I.ISetUser) => ({
    ...state,
    user
  }),
  [t.SET_AUTH_TOKEN]: (state: I.IUserState, { token }: I.ISetTokenRequest) => ({
    ...state,
    token
  }),
  [t.GET_COMMISSION_REQUEST]: (state: I.IUserState) => ({
    ...state,
    isLoading: true
  }),
  [t.GET_COMMISSION_SUCCESS]: (
    state: I.IUserState,
    { user, paginate }: I.IGetCommisionSuccess
  ) => {
    const months = Object.keys(user.data)
    const data = months.reduce((prev, next) => {
      let update = user.data[next]
      if (prev[next]) {
        update = [...prev[next], ...update]
      }

      return {
        ...prev,
        [next]: update
      }
    }, (state.user && state.user.data) || {})
    return {
      ...state,
      isLoading: false,
      user: {
        ...user,
        data: paginate ? data : user.data
      }
    }
  },
  [t.GET_COMMISSION_ERROR]: (state: I.IUserState) => ({
    ...state,
    isLoading: false
  }),
  [t.LOGOUT_REQUEST]: (state: I.IUserState) => ({
    ...state,
    error: null,
    isLoggingOut: true
  }),
  [t.LOGOUT_SUCCESS]: () => ({
    ...initialState
  }),
  [t.ERROR]: (state: I.IUserState, { error }: I.IError) => ({
    ...state,
    error
  }),
  [t.CLEAR_ERROR]: (state: I.IUserState) => ({
    ...state,
    error: null
  })
}

export default function reducer(
  state = initialState,
  action: I.UserInterfaces
) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
