import { Theme } from '@material-ui/core/styles'

const composeGenericStyles = (theme: Theme) => {
  const margins = [0, 1, 2, 3, 4].reduce((prev, curr) => {
    prev[`.m-${curr}`] = {
      margin: theme.spacing(curr)
    }
    return prev
  }, {})

  const marginTops = [0, 1, 2, 3, 4].reduce((prev, curr) => {
    prev[`.mt-${curr}`] = {
      marginTop: theme.spacing(curr)
    }
    return prev
  }, margins)

  const marginBottoms = [0, 1, 2, 3, 4].reduce((prev, curr) => {
    prev[`.mb-${curr}`] = {
      marginBottom: theme.spacing(curr)
    }
    return prev
  }, marginTops)

  return marginBottoms
}

export default composeGenericStyles
