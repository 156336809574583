import { getAuthToken } from 'api/session'
import { constants } from 'config'
import { Action } from 'redux'
import { actionChannel, put, take } from 'redux-saga/effects'
import { error as displayErrorAction, logout } from 'redux/user/actions'

export interface IError extends Action {
  error?: Error | string
  hide?: boolean
}

export default function* errorSaga() {
  const requestChan = yield actionChannel(
    (action: IError) => action.type.match(/_ERROR$/g) && !action.hide
  )
  while (true) {
    const { error } = yield take(requestChan)
    // generic error handlers if any. Should be used to handle uncaught errors in sagas

    if (error) {
      if (
        (error as any) &&
        (error as any).status === 401 &&
        getAuthToken()
      ) {
        yield put(displayErrorAction('Session Expired. Please login again.'))
        yield put(logout())
        continue
      }
    }

    yield put(
      displayErrorAction(
        (error as any).message || constants.defaultErrorMessage
      )
    )
  }
}
