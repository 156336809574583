import * as I from './interfaces'
import types from './types'

export function login(
  email: string,
  password: string,
  redirect: string
): I.ILoginRequest {
  return {
    email,
    password,
    redirect,
    type: types.LOGIN_REQUEST
  }
}

export function signup(email: string, referralCode: string): I.ISignupRequest {
  return {
    email,
    referralCode,
    type: types.SIGNUP_REQUEST
  }
}

export function logout(): I.ILogoutRequest {
  return {
    type: types.LOGOUT_REQUEST
  }
}

export function resetPassword(email: string): I.IResetPasswordRequest {
  return {
    email,
    type: types.PASSWORD_RESET_REQUEST
  }
}

export function setNewPassword(password: string) {
  return {
    password,
    type: types.SET_NEW_PASSWORD_REQUEST
  }
}

export function verifyOtp(otp: string): I.IVerifyOtpRequest {
  return {
    otp,
    type: types.VERIFY_OTP_REQUEST
  }
}

export function getCommision(paginate: boolean): I.IGetCommisionRequest {
  return {
    paginate,
    type: types.GET_COMMISSION_REQUEST
  }
}

export function error(e: string): I.IError {
  return {
    error: e,
    type: types.ERROR
  }
}

export function clearError() {
  return { type: types.CLEAR_ERROR }
}

export function setAuthToken(token: string) {
  return {
    token,
    type: types.SET_AUTH_TOKEN
  }
}
