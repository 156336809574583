import React from 'react'
import { Route } from 'react-router-dom'
import WithAuthorization from './Auth'
import RedirectIf from './RedirectIf'

export const renderProtectedRoute = (
  ComponentRoute:
    | React.ComponentClass<unknown, any>
    | React.FunctionComponent<unknown>,
  path: string
) => (
  <Route path={path}>
    {({ location }) => (
      <WithAuthorization>
        {isAuthorized => (
          <RedirectIf
            condition={!isAuthorized}
            to={{
              pathname: '/login',
              search: '?redirect=' + location.pathname
            }}
          >
            <Route component={ComponentRoute} />
          </RedirectIf>
        )}
      </WithAuthorization>
    )}
  </Route>
)

export { default as Auth } from './Auth'
export { default as RedirectIf } from './RedirectIf'
