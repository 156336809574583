import React from 'react'
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

interface INotificationBar {
  clearNotification: () => void
  clearError: () => void
  error: string
  isOffline: boolean
  notification: string
}

const useStyles = makeStyles(() => ({
  variantSuccess: { fontWeight: 'bold' },
  variantError: { fontWeight: 'bold' },
  variantWarning: { fontWeight: 'bold' },
  variantInfo: { fontWeight: 'bold' }
}))

function Bar({ clearError, clearNotification, error, isOffline, notification }: INotificationBar) {
  const { enqueueSnackbar } = useSnackbar()

  const handleClickVariant = (variant: VariantType, message: string) => () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(`${message}!`, { variant })
  }

  if (error) {
    handleClickVariant('error', error)()
    clearError()
  }

  if (notification) {
    handleClickVariant('success', notification)()
    clearNotification()
  }

  isOffline && handleClickVariant('warning', 'You seem to be offline')()

  return null
}

export default function NotificationBar(props: INotificationBar) {
  const {
    variantInfo,
    variantSuccess,
    variantError,
    variantWarning
  } = useStyles()

  return (
    <SnackbarProvider
      maxSnack={3}
      classes={{
        variantInfo,
        variantSuccess,
        variantError,
        variantWarning
      }}
    >
      <Bar {...props} />
    </SnackbarProvider>
  )
}
