export default {
  requestConfig: {
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_DEV_URL,
    timeout: 180000
  },
  defaultErrorMessage: 'An Error Occured',
  timeouts: {
    buttonClick: 200,
    slides: { enter: 500, exit: 100 }
  }
}
